.block-card {
  .image-container {
    overflow: hidden;
    .card-image {
      width: 100%;
      transition: transform 0.4s ease;
    }
    .card-image:hover {
      transform: scale(1.1);
    }
  }

  .container {
    margin-top: 40px;
    
    @media screen and (max-width: 960px) {
      margin-top: 25px;
    }
    .title {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.26px;

      @media screen and (max-width: 960px) {
        font-size: 22px;
        line-height: 35.2x;
        letter-spacing: 0.22px;
  
      }
    }

    .content {
      margin-top: 30px;
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 0.15px;

      @media screen and (max-width: 960px) {
        margin-top: 11px;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.14px;
  
      }
    }
    .button-container {
      display: flex;
      align-self: flex-end;
      margin-top: 20px;
    }
  }
}
