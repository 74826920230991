.notice-card {
  .card-container {
    overflow: hidden;

    .card-image {
      width: 100%;
      transition: transform 0.4s ease;
    }
    .card-image:hover {
      transform: scale(1.1);
    }
  }

  .box-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    @media screen and (max-width: 960px) {
      margin-top: -10px;
    }

    .box {
      border: 2px solid #5d5d5d;
      padding: 1px 10px;

      span {
        font-size: 11px;
        line-height: 30px;
        letter-spacing: 0.11px;
      }
    }
  }
  .content {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: 0.16px;
  }
}
