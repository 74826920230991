.achrtag {
  display: flex;
  align-items: center;
  &:hover {
    color:#A60012 
  }

  p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 30px;
    padding-right: 15px;
  }

  span {
    width: 42px;

    &:hover {
      background-color: #A60012;
      border-radius: 50%;
      background-image: url("../images/button/arrow.png");
      background-repeat: no-repeat;
      background-position: center;
      
      img {
        opacity: 0;
      }
    }
  }
}
