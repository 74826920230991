.mars-style {
  padding: 100px 50px 130px 50px;
  max-width: 1300px;
  margin: 0 auto;
}

img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 960px) {
  .mars-style {
    padding: 92px 15px 80px 15px;
  }
}

