.footer {
    .mars-style {
      padding-bottom: 45px;
      margin-bottom: -50px;
  
      @media screen and (max-width: 960px) {
        margin-top: -50px;
  
      }
      .upper {
        display: flex;
        text-align: left;
        width: 100%;
        gap: 240px;
        border-bottom: 1px solid #cccccc;
        padding-bottom: 60px;
        @media screen and (max-width: 960px) {
          justify-content: space-between;
          gap: 0;
        }
        .column-container {
          display: flex;
          gap: 180px;
  
          @media screen and (max-width: 960px) {
            flex-direction: column;
            gap: 0;
            width: 100%;
          }
  
          ul {
            li {
              font-size: 14px;
              line-height: 22.4px;
              letter-spacing: 0.28px;
              @media screen and (max-width: 960px) {
                padding: 10px;
              }
            }
          }
        }
      }
  
      .lower {
        display: flex;
        flex-direction: column;
  
        .comp-logo {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-top: 55px;
          @media screen and (max-width: 960px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
  
          .left-part {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 30px;
  
            @media screen and (max-width: 960px) {
              flex-direction: column;
            }
  
            .img-container {
              width: 177px;
            }
            p {
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 0.14px;
            }
          }
          .right-part {
            display: flex;
            flex-direction: row;
            gap: 10px;
  
            @media screen and (max-width: 960px) {
              margin-top: 30px;
            }
  
            .icon {
              width: 35px;
            }
          }
        }
  
        .copyright {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 50px;
          @media screen and (max-width: 960px) {
            flex-direction: column-reverse;
          }
  
          .left {
            p {
              font-size: 12px;
              line-height: 30px;
              letter-spacing: 0.12px;
            }
          }
  
          .right {
            display: flex;
            align-items: center;
            gap: 30px;
  
            @media screen and (max-width: 960px) {
              flex-direction: column;
              gap: 20px;
              margin-bottom: 35px;
            }
            
            .details{
              @media screen and (max-width: 960px) {
                display: flex;
                flex-direction: row;
                gap: 20px;
              }
              p {
                font-size: 12px;
                line-height: 30px;
                letter-spacing: 0.12px;
              }
            }
  
            .logo {
              width: 90px;
            }
          }
        }
      }
    }
  }
  