* {
  scrollbar-width: none;
}

// SECTION 1

.section1 {
  position: relative;
  .container {
    width: 100%;
    height: 668px;
    background-image: url("../images/section1/bg-sect1.png");
    background-size: cover;
    opacity: 0.3;

    @media screen and (max-width: 960px) {
      width: 100%;
      height: 585px;
    }
  }
  .title-container {
    top: 267px;
    left: 100px;
    position: absolute;

    @media screen and (max-width: 960px) {
      top: 220px;
      left: 25px;
    }

    .h1-container {
      width: 100%;
      h1 {
        font-size: 46px;
        line-height: 78px;
        letter-spacing: -1.44px;
        text-align: center;
        font-weight: 700;
      }

      #first-title {
        @media screen and (max-width: 960px) {
          text-align: left;
          font-size: 42px;
          line-height: 38.8px;
          letter-spacing: -1.26px;
          width: 290px;
        }
      }

      #second-title {
        @media screen and (max-width: 960px) {
          font-size: 20px;
          line-height: 28.6px;
          letter-spacing: -0.6px;
          text-align: left;
          margin-left: 25px;
          margin-top: 10px;
        }
      }
    }

    .h3-container {
      margin-left: 25px;
      margin-top: 10px;

      @media screen and (max-width: 960px) {
        margin: 0;
        bottom: -253px;
        left: -24px;
        position: absolute;
        width: 200px;
      }
      h3 {
        text-align: left;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 1.08px;

        @media screen and (max-width: 960px) {
          font-size: 14px;
          line-height: 25.2px;
          letter-spacing: 0.84px;
          font-weight: 600;
        }
      }
    }
  }
}

// SECTION 2

.section2 {
  margin-top: 218px;
  background-color: white;

  @media screen and (max-width: 960px) {
    margin-top: 136px;
  }
  .swiper-container {
   height: auto;
  

    @media screen and (max-width: 960px) {
      height: 310px;
    }
    .swiper-slide img {
      width: 100%;
      height: auto;
    }
  }
}

// SECTION 3

.section3 {
  background-color: white;
  color: black;
  .title-container {
    text-align: center;
    padding: 0 220px;

    @media screen and (min-width: 1600px) {
      padding: 150px 220px;
    }

    @media screen and (max-width: 960px) {
      padding: 0px;
      margin-top: 0px;
    }

    @media screen and (min-width: 800px) and (max-width: 959px) {
      margin-top: 100px;
    }
    .english-title {
      @media screen and (max-width: 960px) {
        display: none;
      }

      p {
        font-size: 18px;
        line-height: 45px;
        font-weight: 600;
      }
    }

    .large-text {
      margin-top: 15px;

      @media screen and (max-width: 960px) {
        text-align: justify;
      }
      h3 {
        font-size: 32px;
        line-height: 51.2px;
        font-weight: 700;
        letter-spacing: 0.32px;
        display: inline-block;
        word-wrap: break-word;

        @media screen and (max-width: 960px) {
          font-size: 26px;
          line-height: 41.6px;
          letter-spacing: 0.26px;
          font-weight: 600;
        }

        .br-pc {
          @media screen and (max-width: 960px) {
            display: none;
          }
        }

        .br-sp {
          display: none;
          @media screen and (max-width: 960px) {
            display: block;
          }
        }
      }
    }

    .small-text {
      margin-top: 75px;

      @media screen and (max-width: 960px) {
        margin-top: 45px;
        text-align: start;
      }

      h4 {
        font-size: 18px;
        line-height: 45px;
        letter-spacing: 0.18px;

        @media screen and (max-width: 960px) {
          font-size: 16px;
          line-height: 32px;
          letter-spacing: 0.16px;
        }

        &:first-child {
          padding-bottom: -20px;
        }
      }
    }
  }

  .reaction-container {
    margin-top: 110px;
    text-align: center;

    .reaction-title {
      h3 {
        font-size: 18px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: 0.18px;
      }
    }

    .reaction-emoji {
      margin-top: 5px;

      .first-group {
        display: flex;

        @media screen and (max-width: 960px) {
          flex-direction: column;
        }
      }

      .second-group {
        display: flex;

        @media screen and (max-width: 960px) {
          flex-direction: column;
        }
      }
    }
  }
}

// SECTION 4

.section4 {
  background-color: white;
  color: black;
  .mars-style {
    margin-top: -25px;
    padding-bottom: 400px;
    @media screen and (max-width: 960px) {
      margin-top: -90px;
      padding-bottom: 300px;
    }
    .brand-container {
      display: flex;
      gap: 30px;

      @media screen and (max-width: 960px) {
        flex-direction: column;
        gap: 65px;
      }
    }
  }
}

// SECTION 5

.section5 {
  // background-color: #45515f;
  position: relative;
  width: 100%;
  height: 2700px;

  @media screen and (max-width: 550px) {
    height: 3000px;
  }
  @media screen and (max-width: 960px) and (min-width: 551px) {
    height: 2700px;
  }
  @media screen and (min-width: 961px) and (max-width: 1300px) {
    height: 2930px;
  }
  .bg-container {
    background-image: url("../images/section5/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1080px;
    position: relative;
    background-position: top 300px left 1px;
    opacity: 0.3;
    filter: brightness(30%);

    @media screen and (max-width: 960px) {
      opacity: 0;
      height: 0;
    }
  }
  .img-container {
    width: 1094px;
    height: 600px;
    position: absolute;
    top: -301px;
    z-index: 0;

    @media screen and (max-width: 960px) {
      width: 350px;
      height: 410px;
      top: -105px;
      left: -45px;
    }

    @media screen and (min-width: 961px) {
      width: 944px;
    }
  }

  .text-container {
    width: 792px;
    height: 180px;
    position: absolute;
    top: 186px;
    right: 0;

    @media screen and (max-width: 960px) {
      width: 225px;
      height: 70px;
      top: 56px;
    }
  }

  .form-container {
    color: white;
    position: absolute;
    top: 350px;
    width: 100%;
    text-align: left;

    @media screen and (max-width: 960px) {
      top: 82px;
    }

    .title-container {
      .title-sect5 {
        font-size: 80px;
        line-height: 96px;
        letter-spacing: 2.4px;
        margin-bottom: 35px;
        font-weight: 600;

        @media screen and (max-width: 960px) {
          font-size: 48px;
          line-height: 54.6px;
          letter-spacing: 1.44x;
        }

        br {
          display: none;

          @media screen and (max-width: 960px) {
            display: block;
          }
        }
      }
      .sect-5-p {
        font-size: 18px;
        line-height: 45px;
        letter-spacing: 0.18px;

        @media screen and (max-width: 960px) {
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 0.16px;
        }
      }
    }

    .card-container {
      margin-top: 10px;
    }
  }
}

// SECTION 6

.section6 {
  .mars-style {
    position: relative;

    .img-container {
      position: absolute;
      width: 680px;
      height: 172px;
      right: -46px;
      top: 75px;

      @media screen and (max-width: 960px) {
        width: 265px;
        height: 67px;
        right: 0;
      }

      @media screen and (min-width: 961px) and (max-width: 1300px) {
        right: 0;
        width: 530px;
      }
    }

    .upper-part {
      .title-container {
        display: flex;
        margin-top: 100px;
        gap: 50px;

        @media screen and (max-width: 960px) {
          flex-direction: column;
          gap: 25px;
          margin-top: 20px;
        }

        h3 {
          font-size: 28px;
          line-height: 32px;
          letter-spacing: 0.84px;
          font-weight: 600;
        }

        p {
          font-size: 16px;
          line-height: 32px;
          letter-spacing: 0.16px;
        }
      }

      .card-container {
        display: flex;
        gap: 20px;
        margin-top: 60px;
        margin-bottom: 50px;

        @media screen and (max-width: 960px) {
          flex-direction: column;
          margin-top: 10px;
          gap: 10px;
        }
      }

      .button-container {
        width: 280px;
        margin: 0 auto;
      }
    }

    .mid-part {
      margin-top: 114px;

      @media screen and (max-width: 960px) {
        margin-top: 48px;
      }
      .channel-section {
        display: flex;

        @media screen and (max-width: 960px) {
          flex-direction: column;
        }

        .button-container {
          display: flex;
          flex-direction: column;
          width: 35%;
          justify-content: flex-start;
          h3 {
            font-size: 28px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.84px;
            margin-bottom: 30px;
          }
          .button-sp {
            @media screen and (max-width: 960px) {
              display: none;
            }
          }
        }

        .button-pc {
          display: none;

          @media screen and (max-width: 960px) {
            display: flex;
            align-self: flex-end;
            margin-top: 30px;
          }
        }
      }
    }

    .lower-part {
      margin-top: 120px;
      @media screen and (max-width: 960px) {
        margin-top: 85px;
      }
      .lower-container {
        display: flex;

        @media screen and (max-width: 960px) {
          flex-direction: column;
          gap: 0;
        }
        .lower-title {
          width: 21%;
          @media screen and (max-width: 960px) {
            width: 100%;
          }
          h3 {
            font-size: 28px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.84px;
            margin-bottom: 50px;

            @media screen and (max-width: 960px) {
              margin-bottom: 20px;
            }
          }

          .button-pc {
            @media screen and (max-width: 960px) {
              display: none;
            }
          }
        }
        .lower-dates {
          border-bottom: 1px solid #cccccc;
          width: 80%;
          @media screen and (max-width: 960px) {
            width: 100%;
          }
          .dates {
            display: flex;
            gap: 50px;
            padding: 50px 0;
            border-top: 1px solid #cccccc;

            @media screen and (max-width: 960px) {
              flex-direction: column;
              gap: 10px;
            }
            span {
              font-size: 14px;
              line-height: 32px;
              letter-spacing: 0.14px;
            }

            p {
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.16px;
            }
          }
        }
        .button-sp {
          display: none;
          @media screen and (max-width: 960px) {
            display: flex;
            align-self: flex-end;
            margin-top: 30px;
          }
        }
      }
    }
  }
}

// SECTION 7
.section7 {
  .mars-style {
    margin-top: -100px;
    .card-container {
      display: flex;
      gap: 55px;

      @media screen and (max-width: 960px) {
        flex-direction: column;
        gap: 50px;
      }
    }
  }
}

//SECTION 8
.section8 {
  background-color: #ecf0f5;
  .container {
    .contact {
      padding: 50px 100px;
      @media screen and (max-width: 960px) {
        padding: 35px 15px;
      }

      span {
        color: #b2152d;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.36px;
        @media screen and (max-width: 960px) {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.36px;
        }
      }
      h3 {
        font-size: 30px;
        line-height: 48px;
        font-weight: 600;
        letter-spacing: 0.3px;
        @media screen and (max-width: 960px) {
          margin-top: 20px;
          font-size: 16px;
          line-height: 25.6px;
          letter-spacing: 0.16px;
        }
      }
    }

    .icon-container {
      display: flex;
      justify-content: center;
      overflow: hidden;
      @media screen and (max-width: 960px) {
        flex-direction: column;
      }

      .message-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        border: 1px solid #cccccc;
        gap: 30px;
        padding: 66.5px 0;

        &:hover img{
          content: url("../images/section8/redmessage.png");
        }
        &:hover {
          color: #b2152d;
        }

        @media screen and (max-width: 960px) {
          width: 100%;
          padding: 45px 0;
        }

        .icon {
          width: 67px;
        }

        p {
          font-size: 20px;
          line-height: 28.8px;
          font-weight: 600;
          letter-spacing: 0.2px;

          @media screen and (max-width: 960px) {
            font-size: 17px;
            letter-spacing: 0.17px;
          }
        }
      }

      .phone-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        border: 1px solid #cccccc;
        gap: 25px;

         &:hover img{
          content: url("../images/section8/redphone.png");
        }
         &:hover {
            color: #b2152d;
          }

        @media screen and (max-width: 960px) {
          width: 100%;
          padding: 25px 0;
        }

        .icon {
          width: 67px;
        }

        .details {

          .title {
            font-size: 20px;
            line-height: 40px;
            letter-spacing: 0.2px;
            font-weight: 600;
            @media screen and (max-width: 960px) {
              font-size: 17px;
              letter-spacing: 0.17px;
            }

          
          }

          .number {
            font-size: 41px;
            line-height: 39px;
            letter-spacing: 0.41px;
            @media screen and (max-width: 960px) {
              font-size: 30px;
              letter-spacing: 0.3px;
            }
          }

          .time {
            font-size: 15px;
            line-height: 39px;
            letter-spacing: 0.15px;
            @media screen and (max-width: 960px) {
              font-size: 13px;
              letter-spacing: 0.13px;
            }
          }
        }
      }
    }

    .other-contact {
      .mars-style {
        margin-top: 0;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 960px) {
          flex-direction: column;
          margin-top: -62px;
          gap: 40px;
        }
        .contact-container {
          display: flex;
          gap: 43px;
          width: 50%;
          text-align: center;

          @media screen and (max-width: 960px) {
            flex-direction: column;
            width: 100%;
            gap: 0;
          }
          .contact-title {
            @media screen and (max-width: 960px) {
              text-align: start;
            }
            h3 {
              font-size: 14px;
              line-height: 32px;
              font-weight: 700;
              letter-spacing: 0.14px;
            }
          }
          .contact-details {
            text-align: start;

            .details {
              font-size: 14px;
              line-height: 25.2px;
              letter-spacing: 0.14px;
            }

            .map-icon {
              display: flex;
              width: 100%;
              gap: 10px;
              align-items: center;
              .map {
                font-size: 13px;
                line-height: 25.2px;
                letter-spacing: 0.13px;

                &:hover {
                  color: #b2152d;
                }
              }
            }
          }
        }
      }
    }
  }
}

//PAGE TOP BUTTON
.page-top {
  background-color: #667381;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    display: flex;
    gap: 25px;
    padding: 30px 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    .image-container {
      width: 20px;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 16px;
      line-height: 28.8px;
      letter-spacing: 0.16px;
    }
  }
}
