
.ads-container {
    display: flex;
    position: fixed;
    bottom: 230px;
    right: 0;
    z-index: 999;

    .ads {
        width: 250px;
    }

    .close {
        width: 26px;
        margin-left: -10px;
        margin-top: -15px;
    }
}