.cookies-modal {
    transition: right 0.3s ease-in-out;
    z-index: 999;
  
    .cookies-content {
      background-color: #6B6B6B;
      padding: 20px 40px;
      display: flex;
      color: white;
      align-items: center;
      text-align: center;
      bottom: 0;
      position: fixed;
      width: 100%;
      z-index: 999;

      @media screen and (max-width: 1400px) {
        flex-direction: column;
      }

      p {
        font-size: 14px;
      }
  
      .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 25px;
        margin-left: 30px;

        @media screen and (max-width: 450px) {
            flex-direction: column;
            gap: 10px;
          }
  
        button {
          padding: 5px 38px;
          border: none;
          border-radius: 30px;
          cursor: pointer;
          font-size: 14px;
  
          &:first-child {
            background-color: #FCCA40; 
            color: black;
          }
  
          &:last-child {
            background-color: #FCCA40; 
            color: black;
          }
  
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }