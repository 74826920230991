.section-legend {
    margin-top: 112px;
    background-color: white;
    padding: 5px 30px;
  
    p {
      font-size: 11px;
      line-height: 32px;
      letter-spacing: 0.11px;
    }
  }

  //SECTION 1
  .section1 {
    position: relative;
    width: 100%;
    height: 450px;
  
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../images/section1/bg-sect1.png");
      background-size: cover;
      opacity: 0.2;
    }
  
    .mars-style {
      position: relative;
      text-align: left;
      display: flex;
      align-items: center;
      z-index: 1;
  
      .title {
        color: black;
  
        span {
          font-size: 16px;
          line-height: 32px;
          letter-spacing: 0.16px;
        }
  
        h3 {
          font-size: 60px;
          line-height: 38px;
          letter-spacing: 0.6px;
          margin-top: 20px;

          @media screen and (max-width: 960px) {
             font-size: 36px;
             line-height: 46.8px;
             letter-spacing: 0.36px;
          }
        }
      }
    }
  }

  .ftext {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    line-height: 41.4px;
    padding: 0;
    @media screen and (max-width: 960px) {
      font-size: 17px;
      letter-spacing: 0.17px;
      line-height: 39.1px;
      padding: 0;
    }
  }
  // info text
  
  // position section
  .positionSec {
    .positionSec--container {
      .underline--box {
        padding: 42px 0 32px;
        @media screen and (max-width: 960px) {
          padding: 42px 0 25px;
        }
        .red-underline {
          border-bottom: 2px solid #b2152d;
          display: inline-block;
          width: 50px;
          margin-bottom: -2px;
        }
      }
      .posiTxt {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 0.4px;
        line-height: 64px;
        padding-bottom: 36px;
        @media screen and (max-width: 960px) {
          font-size: 28px;
          letter-spacing: 0.28px;
          line-height: 44.8px;
          padding-bottom: 37px;
        }
      }
      .box {
        padding: 35px 0 35px 41px;
        margin-bottom: 30px;
        border: 1px solid #707070;
        @media screen and (max-width: 960px) {
          padding: 19px 0 19px 20px;
        }
        .ppdot {
          &:nth-child(2) {
            padding-bottom: 15px;
          }
        }
        .boxttle {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0.18px;
          line-height: 28.8px;
          padding-bottom: 25px;
          @media screen and (max-width: 960px) {
            font-size: 17px;
            letter-spacing: 0.17px;
            line-height: 27.2px;
          }
        }
        .pdot {
          font-size: 16px;
          letter-spacing: 0.16px;
          line-height: 25.6px;
          font-weight: 400;
          @media screen and (max-width: 960px) {
            font-size: 15px;
            letter-spacing: 0.15px;
            line-height: 24px;
          }
        }
      }
      .texts {
        .txt {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.16px;
          line-height: 32px;
          padding-bottom: 30px;
          @media screen and (max-width: 960px) {
            font-size: 15px;
            letter-spacing: 0.15px;
            line-height: 30px;
          }
        }
        .div {
          display: flex;
          gap: 10px;
          .txtt {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.14px;
            line-height: 22.4px;
            padding-bottom: 130px;
            @media screen and (max-width: 960px) {
              font-size: 13px;
              letter-spacing: 0.15px;
              line-height: 20.8px;
              padding-bottom: 80px;
            }
          }
        }
      }
      .hdrTtl {
        font-size: 34px;
        font-weight: 500;
        letter-spacing: 0.34px;
        line-height: 54.4px;
        padding-bottom: 58px;
        @media screen and (max-width: 960px) {
          font-size: 26px;
          letter-spacing: 0.26px;
          line-height: 41.6px;
          padding-bottom: 35px;
        }
      }
      .box2 {
        padding-bottom: 40px;
        @media screen and (max-width: 960px) {
          padding-bottom: 60px;
        }
        .bx2ttle {
          font-size: 26px;
          font-weight: 700;
          letter-spacing: 0.26px;
          line-height: 41.6px;
          padding-bottom: 28px;
          @media screen and (max-width: 960px) {
            font-size: 22px;
            letter-spacing: 0.22px;
            line-height: 36.8px;
            padding-bottom: 31px;
          }
        }
        .div {
          display: flex;
          gap: 10px;
          align-items: center;
          &:nth-child(2) {
            padding-bottom: 15px;
          }
          &:nth-child(3) {
            padding-bottom: 15px;
          }
          .pdott {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.16px;
            line-height: 26.6px;
            @media screen and (max-width: 960px) {
              font-size: 15px;
              letter-spacing: 0.15px;
              line-height: 24px;
            }
          }
        }
      }
      .box3 {
        padding-bottom: 30px;
        .bx2ttle2 {
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.2px;
          line-height: 32px;
          padding-bottom: 28px;
          @media screen and (max-width: 960px) {
            font-size: 18px;
            letter-spacing: 0.18px;
            padding-bottom: 26px;
          }
        }
        .div {
          display: flex;
          gap: 10px;
          align-items: center;
          &:nth-child(2) {
            padding-bottom: 15px;
          }
          &:nth-child(3) {
            padding-bottom: 15px;
          }
          &:nth-child(4) {
            padding-bottom: 15px;
          }
          .pdots {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.16px;
            line-height: 26.6px;
            @media screen and (max-width: 960px) {
              font-size: 15px;
              letter-spacing: 0.15px;
              line-height: 24px;
            }
          }
        }
      }
      .div {
        display: flex;
        gap: 10px;
        align-items: center;
        .ppdots {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.14px;
          line-height: 22.4px;
          @media screen and (max-width: 960px) {
            font-size: 13px;
            letter-spacing: 0.13px;
            line-height: 20.8px;
          }
        }
      }
    }
  }
  // position section
  
  // application section
  .applic {
    .aplic--container {
      .underline--box {
        padding: 42px 0 32px;
        @media screen and (max-width: 960px) {
          padding: 42px 0 25px;
        }
        .red-underline {
          border-bottom: 2px solid #b2152d;
          display: inline-block;
          width: 50px;
          margin-bottom: -2px;
        }
      }
      .appliTxt {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 0.4px;
        line-height: 64px;
        padding-bottom: 36px;
        @media screen and (max-width: 960px) {
          font-size: 28px;
          letter-spacing: 0.28px;
          line-height: 44.8px;
          padding-bottom: 37px;
        }
      }
      .info {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.16px;
        line-height: 32px;
        @media screen and (max-width: 960px) {
          font-size: 15px;
          letter-spacing: 0.15px;
          line-height: 30px;
        }
      }
      .button {
        width: 280px;
        margin: 0 auto;
        padding-top: 10px;
        @media screen and (max-width: 960px) {
          padding: 26px 0;
        }
      }
      .infoo {
        padding-bottom: 30px;
      }
      .divv {
        .div {
          display: flex;
          gap: 10px;
          &:nth-child(1) {
            padding-bottom: 10px;
          }
          .pdott {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.14px;
            line-height: 22.4px;
            @media screen and (max-width: 960px) {
              font-size: 13px;
              letter-spacing: 0.13px;
              line-height: 20.8px;
            }
          }
        }
      }
    }
  }
  // application section
  
  // question section
  .Question {
    .que--container {
      .underline--box {
        padding: 42px 0 32px;
        @media screen and (max-width: 960px) {
          padding: 42px 0 25px;
        }
        .red-underline {
          border-bottom: 2px solid #b2152d;
          display: inline-block;
          width: 50px;
          margin-bottom: -2px;
        }
      }
      .queTxt {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 0.4px;
        line-height: 64px;
        padding-bottom: 36px;
        @media screen and (max-width: 960px) {
          font-size: 28px;
          letter-spacing: 0.28px;
          line-height: 44.8px;
          padding-bottom: 37px;
        }
      }
  
      .container {
        .accordion--container {
          .drop_btn {
            padding: 15px 20px;
            background-color: #f7f7f7;
            margin-bottom: 5px;
            cursor: pointer;
            .ptext {
              align-items: center;
              display: flex;
              width: calc(100% - 20px);
              .qbox {
                max-width: 16px;
                margin-right: 20px;
              }
              .ques {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.16px;
                line-height: 32px;
                @media screen and (max-width: 760px) {
                  font-size: 15px;
                  letter-spacing: 0.15px;
                  line-height: 24px;
                }
              }
            }
          }
          .drop_down {
            max-height: 0;
            overflow: hidden;
            transition: max-height 2s ease;
            .ans {
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0.16px;
              line-height: 32px;
              padding: 20px 30px 20px 56px;
              @media screen and (max-width: 760px) {
                font-size: 15px;
                letter-spacing: 0.15px;
                line-height: 24px;
                padding: 16px 0;
              }
            }
            &.open {
              max-height: 200px;
            }
          }
        }
      }
    }
  }
  
  // question section
  