.card-containers{
    display: flex;
    flex-direction: row;
    gap: 180px;
    color: white;
    margin-top: 150px;

    @media screen and (max-width: 960px) {
            flex-direction: column;
            gap: 20px;
            margin-top: 75px;
    }

    .number {
       font-size: 18px;
       line-height: 26px;
       letter-spacing: 0.54px;
    }

    .title-container {
        text-align: left;
        .subtitle {
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.42;
            @media screen and (max-width: 960px) {
                font-size: 12px;
                line-height: 26px;
                letter-spacing: 0.36px;
            }
            
        }
        .title {
            font-size: 34px;
            line-height: 32px;
            letter-spacing: 1.02px;
            margin-top: 10px;

            @media screen and (max-width: 960px) {
                font-size: 28px;
                line-height: 44.8px;
                letter-spacing: 0.28px;
                margin-top: 5px;
            }

        }

        .content {
            margin-top: 25px;
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0.16px;

            @media screen and (max-width: 960px) {
                font-size: 15px;
                line-height: 30px;
                letter-spacing: 0.15px;
            }
        }
    }
    .text-box-container {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
    }
    .button-container {
        width: 325px;
        margin-top: 35px;

        @media screen and (max-width: 960px) {
            width: 100%;
        }
    }
    .tag {
        display: none;
    }

}