.text-box {
    border: 2px solid #7D868F;
    padding: 20px;
    text-align: start;
    width: 490px;
    height: auto;

    @media screen and (max-width: 960px) {
        padding: 15px;
    }

    .text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.14px;
    }
}