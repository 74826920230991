.card-review {
  width: 33%;
  display: flex;
  align-items: center;
  padding: 30px 30px 30px 30px;
  img {
    width: 50%;
  }

  .review-content {
    text-align: left;
    p {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }
}

@media screen and (max-width: 960px) {

  .card-review {
    width: 100%;
    gap: 0;
    padding: 20px;

    img {
      width: 50%;
    }
  }
}