//SECTION LEGEND
.section-legend {
  margin-top: 112px;
  background-color: white;
  padding: 5px 30px;

  p {
    font-size: 11px;
    line-height: 32px;
    letter-spacing: 0.11px;
  }
}

//SECTION 1
.section1 {
  position: relative;
  width: 100%;
  height: 450px;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/section1/bg-sect1.png");
    background-size: cover;
    opacity: 0.2;
  }

  .mars-style {
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    z-index: 1;

    .title {
      color: black;

      span {
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.16px;
      }

      h3 {
        font-size: 60px;
        line-height: 38px;
        letter-spacing: 0.6px;
        margin-top: 20px;
      }
    }
  }
}

//SECTION 2
.section2 {
  .mars-style {

    margin-top: -190px;
    .first-part {
      span {
        display: flex;
        border: 1px solid #b2152d;
        width: 50px;
        margin-bottom: 50px;
      }

      .title {
        margin-bottom: 60px;
        h3 {
          font-size: 40px;
          line-height: 64px;
          letter-spacing: 0.4px;
        }
      }

      .details {
        p {
          font-size: 18px;
          line-height: 32px;
          letter-spacing: 0.18px;
          margin-bottom: 30px;
        }

        .list {
          margin-left: 40px;
          p {
            margin-bottom: 35px;
          }
        }
      }
    }

    .second-part {
      span {
        display: flex;
        border: 1px solid #b2152d;
        width: 50px;
        margin-bottom: 50px;

        @media screen and (max-width: 960px) {
            margin-bottom: 10px;
        }
      }

      .title {
        h3 {
          font-size: 40px;
          line-height: 64px;
          letter-spacing: 0.4px;
        }
      }

      .list-container {
        margin-top: 40px;

        .list {
          display: flex;
          flex-direction: row;
          padding: 35px;
          width: 100%;
          border-bottom: 1px solid #cccccc;

          @media screen and (max-width: 960px) {
            flex-direction: column;
            padding: 15px;
          }

          .left-list {
            font-size: 16px;
            line-height: 28.8px;
            letter-spacing: 0.16px;
            border: unset;
            width: 30%;
          }

          .right-list {
            width: 70%;

            p {
              font-size: 16px;
              line-height: 28.8px;
              letter-spacing: 0.16px;
            }
            .bold {
              font-weight: 700;
            }

            .bolds {
              font-weight: 700;
              margin-top: 35px;
            }
          }
        }
      }
    }
  }
}

//SECTION 8
.section8 {
  background-color: #ecf0f5;
  .container {
    .contact {
      padding: 50px 100px;
      @media screen and (max-width: 960px) {
        padding: 35px 15px;
      }

      span {
        color: #b2152d;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.36px;
        @media screen and (max-width: 960px) {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.36px;
        }
      }
      h3 {
        font-size: 30px;
        line-height: 48px;
        font-weight: 600;
        letter-spacing: 0.3px;
        @media screen and (max-width: 960px) {
          margin-top: 20px;
          font-size: 16px;
          line-height: 25.6px;
          letter-spacing: 0.16px;
        }
      }
    }

    .icon-container {
      display: flex;
      justify-content: center;
      overflow: hidden;
      @media screen and (max-width: 960px) {
        flex-direction: column;
      }

      .message-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        border: 1px solid #cccccc;
        gap: 30px;
        padding: 66.5px 0;
        @media screen and (max-width: 960px) {
          width: 100%;
          padding: 45px 0;
        }

        .icon-container {
          width: 67px;
        }

        p {
          font-size: 20px;
          line-height: 28.8px;
          font-weight: 600;
          letter-spacing: 0.2px;

          @media screen and (max-width: 960px) {
            font-size: 17px;
            letter-spacing: 0.17px;
          }
        }
      }

      .phone-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        border: 1px solid #cccccc;
        gap: 25px;

        @media screen and (max-width: 960px) {
          width: 100%;
          padding: 25px 0;
        }

        .icon {
          width: 67px;
        }

        .details {
          .title {
            font-size: 20px;
            line-height: 40px;
            letter-spacing: 0.2px;
            font-weight: 600;
            @media screen and (max-width: 960px) {
              font-size: 17px;
              letter-spacing: 0.17px;
            }
          }

          .number {
            font-size: 41px;
            line-height: 39px;
            letter-spacing: 0.41px;
            @media screen and (max-width: 960px) {
              font-size: 30px;
              letter-spacing: 0.3px;
            }
          }

          .time {
            font-size: 15px;
            line-height: 39px;
            letter-spacing: 0.15px;
            @media screen and (max-width: 960px) {
              font-size: 13px;
              letter-spacing: 0.13px;
            }
          }
        }
      }
    }

    .other-contact {
      .mars-style {
        margin-top: 0;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 960px) {
          flex-direction: column;
          margin-top: -62px;
          gap: 40px;
        }
        .contact-container {
          display: flex;
          gap: 43px;
          width: 50%;
          text-align: center;

          @media screen and (max-width: 960px) {
            flex-direction: column;
            width: 100%;
            gap: 0;
          }
          .contact-title {
            @media screen and (max-width: 960px) {
              text-align: start;
            }
            h3 {
              font-size: 14px;
              line-height: 32px;
              font-weight: 700;
              letter-spacing: 0.14px;
            }
          }
          .contact-details {
            text-align: start;

            .details {
              font-size: 14px;
              line-height: 25.2px;
              letter-spacing: 0.14px;
            }

            .map-icon {
              display: flex;
              width: 100%;
              gap: 10px;
              align-items: center;
              .map {
                font-size: 13px;
                line-height: 25.2px;
                letter-spacing: 0.13px;
              }
            }
          }
        }
      }
    }
  }
}
